import React, { useContext, useEffect } from "react";
import Layout from "@/shared/layout/Layout";
import css from "./home.module.scss";
import { withPageWrapper } from "@/services/utils/withPageWrapper";
import PageWrapper from "@/shared/layout/page/PageWrapper";
import HomeHeader from "./components/Header/HomeHeader";
import HomeBlog from "./components/Blog/HomeBlog";
import HomeThemes from "./components/Themes";
import HomePerks from "./components/Perks/HomePerks";
import HomeCreationGuide from "./components/CreationGuide/CreationGuide";
import UserContext from "../../context/user/UserContext";
import IAdvizeService from "@/services/utils/IAdvizeService";
import PagesDataService from "@/services/domain/PagesDataService";
import PublicationsService from "@/services/domain/PublicationsService";
import ProductsService from "@/services/domain/ProductsService";
import OpenGraphHead from "@/shared/OpenGraphHead/OpenGraphHead";
import HomeGifts from "./components/HomeGifts/HomeGifts";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { COMMON_NAMESPACES } from "../../i18n";
import HomeFAQ from "./components/HomeFAQ";
import HowTo from "@/components/HowTo/HowTo";

const namespacesRequired = [
  ...COMMON_NAMESPACES,
  "home",
  "pot",
  "concept",
  "publications",
];

const Home = ({ t, principal, pagesData, topPublications, gifts }) => {
  const userContext = useContext(UserContext);
  const { activeList, isFetching } = userContext;

  useEffect(() => {
    IAdvizeService.injectPersonalDataScript(
      { principal },
      IAdvizeService.IADVIZE_PAGE_TYPES.HOMEPAGE
    );
  }, []);

  return (
    <Layout principal={principal} homePage={true}>
      <PageWrapper customClass={css.noPadding}>
        <OpenGraphHead
          title={t("home:meta-title")}
          description={t("home:meta-description")}
        />

        <HomeHeader
          activeList={activeList}
          isFetching={isFetching}
          pagesData={pagesData}
        />

        <HomeCreationGuide />
        <HomePerks />
        <HowTo activeList={activeList} />

        <HomeGifts gifts={gifts.products} />

        <HomeBlog publications={topPublications} />
        <HomeThemes />

        <HomeFAQ />
      </PageWrapper>
    </Layout>
  );
};

Home.getInitialProps = async (ctx) => {
  const [pagesData, topPublications, gifts] = await Promise.all([
    PagesDataService.get(ctx),
    PublicationsService.getTop(4, ctx).catch((error) => {
      console.log(
        "Error while retrieving top four publications for home page : ",
        JSON.stringify(error)
      );
      return null;
    }),
    ProductsService.find({}, 15, 0, ctx),
  ]);

  console.log('dkdkdkdkkd', gifts)
  return { pagesData, topPublications, gifts };
};

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, namespacesRequired)),
    },
  };
}

export default withPageWrapper({ namespacesRequired: namespacesRequired })(
  Home
);
